.detail-user {
  .predict-pnl {
    &-win-rate {
      position: relative;
      &-item {
        position: absolute;
        top: 50px;
        left: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-text-strong {
          font-weight: 700;
        }
      }
    }
  }
}
