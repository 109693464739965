.p-15 {
  padding: 15px;
}

.positon-swiper-wrapper {
  .swiper-wrapper {
    align-items: center;
    display: flex;
  }
  .swiper-slide {
    width: 320px;
  }
}

.h-16 {
  height: 4rem /* 64px */;
}

.m-auto {
  margin: auto;
}

.w-60 {
  width: 15rem /* 240px */;
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.left-0 {
  left: 0;
}
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.justify-center {
  justify-content: center;
}
.absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
