#admin-iq {
  .sider-layout-sidebar {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    img {
      height: 48px;
    }
  }
  .site-layout {
    margin-left: 200px;
  }
  .site-layout .site-layout-background {
    &.content {
      min-height: calc(100vh - 100px);
      overflow: initial;
      width: 100%;
      background: #e5e5e5;
    }
  }
}

.page-header {
  background-color: inherit;
  margin: 0;
  padding: 5px 24px;
  &-heading {
    display: flex;
    justify-content: space-between;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  margin: 0;
}

.search-container {
  background-color: #fff;
  padding: 15px;
}

.list-container {
  background-color: #fff;
  margin-top: 10px;
}

.form-item-container {
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 5px;
}

.list-collapse-container {
  background-color: #fff;
  margin-top: 10px;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  .round-collapse {
  }
}
